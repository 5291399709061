//Страница с настройками пользователя

import React from 'react';
import { FormSettings } from '../../molecules'
import {useFormValidation} from '../../../utils/Validator'
import {Counter, filtrSearchArr} from '../../../utils/utils'; 
import { useSelector, useDispatch } from 'react-redux'  
import { updateUser } from '../../../store/api'  

export function Settings(props) {
  const dispatch = useDispatch()
  const { currentUser, statusUser } = useSelector(state => state.currentUser)
  const { lib, statusLib } = useSelector(state => state.lib)
 
  const { 
    values, 
    handleChange,
  } = useFormValidation({ minute: currentUser.settings.cookingTime, calories: currentUser.settings.maxCals || 1800});
  const [choiceIngredient, setChoiceIngredient] = React.useState([]) // выбранный ингредиент
  const { 
    capacity,
    handleCountClickPlus,
    handleCountClickMinus
  } = Counter({capacity: currentUser.settings.sumFamily});
  const [types, setTypes] = React.useState(statusLib === 'resolved' ? lib.typeFoods : [])
  const [meals, setMeals] = React.useState([])
  const [except, setExcept] = React.useState([])
  const [period, setPeriod] = React.useState('everyday')
  const [weekday, setWeekday] = React.useState([])
  const typesDisabled = types !== undefined ? types.some(i=> i.boolean) : false
  const mealsDisabled = meals.length > 0 ? meals.some(i=> i.boolean): false
  const [isHovering, setIsHovering] = React.useState(false);
  const submitDisabled = values.minute === undefined || values.minute === '' || values.minute < 2 ||
        capacity === undefined || capacity === '' || !typesDisabled || !mealsDisabled
  const [ingredient, setIngredient] = React.useState('')
  const [filtrMassiv, setFiltrMassiv] = React.useState([]);
  const [ingredientsData, setIngredientsData] = React.useState([])
  const [hidden, sethidden] = React.useState(true)
  const {ingredients} = useSelector(state => state.ingredients)
  const addIngDisabled = ingredient === undefined || ingredient === ''
  const [kitchenware, setKitchenware] = React.useState([]) 
  const [choice, setChoice] = React.useState(false);

  //Заполнение данных из базы при загрузке страницы
  React.useEffect(() => {
    if(statusLib === 'resolved' && currentUser) {
      const newtype = [...lib.typeFoods]
      newtype.map(i=> {
        let arr = currentUser.settings.typeOfDiet.find(k => k._id === i._id);
        if (arr) {
          let updatedObj = {
            _id: i._id,
            nameRu: i.nameRu ,
            nameEn: i.nameEn,
            desc: i.desc, 
            boolean: arr.boolean,
          }; 
          newtype[newtype.indexOf(i)] = updatedObj
        }
      })
      setTypes(newtype)
      //setTypes((types) => types.map((c) => currentUser.settings.typeOfDiet.find(t=> t.nameEn === c.nameEn) ? currentUser.settings.typeOfDiet.find(t=> t.nameEn === c.nameEn) : c))
      setExcept(lib.except)
      setIngredientsData(currentUser.settings.stopProducts)
      setWeekday(currentUser.settings.period)
      setMeals(currentUser.settings.powerMode) 
      setPeriod(currentUser.settings.periodAn) 
      setKitchenware(currentUser.settings.kitchenware ? currentUser.settings.kitchenware : lib.kitchenware)
  }
  }, [currentUser, statusLib]);
 
  //Добавление типов питания в массив
  const handleCheckBox = (item) => {
    setTypes(() => types.map((c) => c.nameEn === item.nameEn ? item : c));
  };
  
   //Добавление типов питания в массив
  function handleChoiceCal() {  
    setChoice(!choice)
  }  
  
  const handleCheckBoxKitchenware = (item) => {
    setKitchenware(() => kitchenware.map((c) => c.nameEn === item.nameEn ? item : c));
  };
   
  const handleCheckBoxWeekday = (item) => {
    if(item.nameEn === 'everyday') {
      setWeekday(() => weekday.map((c) => ({
        ...c,
        boolean: item.boolean
      })))
    } else {
      setWeekday(() => weekday.map((c) => c.nameEn === item.nameEn ? item : c))
    }
  };

  const handleCheckBoxPeriod = (e) => {
    setPeriod(e.target.value) 
  };

  //Обработка Чекбоксов режима питания
  const handleCheckBoxMeals = (item) => { 
    setMeals(() => meals.map((c) => c.nameEn === item.nameEn ? item : c)) 
  };
  
  // Подстановка автоматически значения в инпут из выбранного
  function handleAutoText(text) { 
    setIngredient(text.name)
    setChoiceIngredient(text._id)
    sethidden(false) 
  }
 
  function handleExpectCheck(item, value) { 
    if(value) {
      setIngredientsData(()=> ingredientsData.filter((c) => !item.ingredients.some(k=> c===k)));
    } else {
     setIngredientsData([...ingredientsData, ...item.ingredients])
    }
  }

  //Изменение инпута ингредиентов
  function handleChangeInput(e) {
    handleFiltrClick(e.target.value)
    setIngredient(e.target.value)
    sethidden(true)
  }

  //Поиск в инпуте по ингридиенту
  function handleFiltrClick(keyword) {
    if(keyword!=='') {
    const newMassiv = filtrSearchArr(ingredients, keyword)
    setFiltrMassiv(newMassiv)
    } else {
      setFiltrMassiv([])
    }
  }
  
  //удаление ингредиентов
  function handleDeleteIng(id) {
    setIngredientsData((i)=> i.filter((c) => c !== id));
  }

  //добавление ингредиентов в массив
  function handleAddIng() {
    setIngredientsData([...ingredientsData, choiceIngredient])
    setIngredient('')
  } 

  //обработка клика по вопросу 
  function handleClickAttention() {
    setIsHovering(!isHovering)
  }
  
  //Отправка формы
  function handleSubmit(e) {
    e.preventDefault(); 
    const settings = {
      sumFamily: capacity,
      periodAn: period,
      typeOfDiet: types,
      period: weekday,
      powerMode: meals,
      cookingTime: Number(values.minute),
      stopProducts: ingredientsData,
      kitchenware: kitchenware,
      maxCals: Number(values.calories),
      notCals: choice
    }
    dispatch(updateUser(settings))
  }
 
  return (
    <>
    {statusLib === 'resolved' &&
    <FormSettings 
    onChange={handleChange}
    name={values.name}
    types={types}
    period={period}
    submitDisabled={submitDisabled}
    capacity={capacity}
    handleCountClickPlus={handleCountClickPlus}
    handleCountClickMinus={handleCountClickMinus}
    hours={values.hours}
    minute={values.minute}
    handleCheckBox={handleCheckBox}
    handleCheckBoxMeals={handleCheckBoxMeals}
    handleCheckBoxWeekday={handleCheckBoxWeekday}
    handleCheckBoxPeriod={handleCheckBoxPeriod}
    meals={meals}
    weekday={weekday}
    handleChoice={handleChoiceCal}
    choice={choice}
    onSubmit={handleSubmit}
    handleChangeInput={handleChangeInput}
    handleAutoText={handleAutoText}
    filtrMassiv={filtrMassiv}
    hidden={hidden}
    calories={values.calories}
    ingredient={ingredient}
    handleAddIng={handleAddIng}
    ingredientsData={ingredientsData}
    onDelete={handleDeleteIng}
    addIngDisabled={addIngDisabled}
    kitchenware={kitchenware}
    handleCheckBoxKitchenware={handleCheckBoxKitchenware}
    typesDisabled={typesDisabled}
    mealsDisabled={mealsDisabled}
    handleClickAttention={handleClickAttention}
    isHovering={isHovering}
    except={except}
    handleExpectCheck={handleExpectCheck}
    type={props.type}
    statusUser={statusUser}
    /> 
  }
  </>
  )
}