import React from 'react';
import { PDFDownloadLink, Document, Page, Text,Font, View, StyleSheet, PDFViewer  } from '@react-pdf/renderer';
import { ProductCat, PrintThisComponent} from '../../atoms'
import { useSelector } from 'react-redux'
import { sortChoice } from '../../../utils/utils.js'

Font.register({
  family: "Roboto",
  src:
    "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-light-webfont.ttf"
});

const styles = StyleSheet.create({ 
  page: {
    fontFamily: "Roboto",
    flexDirection: 'row',
    backgroundColor: '#E4E4E4'
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1
  },
  header: {
    fontSize: 28,
    marginBottom: 10,
    fontWeight: '700',
    textAlign: 'center'
  },
  subtitle: {
    fontSize: 18,
    marginBottom: 10,
    fontWeight: '700',
    textAlign: 'center'
  },
  text: {
    fontSize: 14,
  },
  table: {
    display: "flex",
    gap: 10

  },
  buttons: {
    color: '#fff',
    textDecoration: 'none'
  },
  choice: {
    textDecoration: 'line-through',
    color: 'grey'
  }
});

function PdfDownload (props) {
  const catIn=props.catIn
  const checkFoodList= props.checkFoodList
  const startDay= props.startDay
  const finishDay= props.finishDay
  const  servings= props.servings 
  const { ingredients } = useSelector(state => state.ingredients)
 
 const newarr = checkFoodList.map((item)=> {
    const product =  ingredients.find(i => i._id === item.id)
    return {name: product.nameRu + '' + product.trait || product.name, ...item }
  })
  const sort =sortChoice(newarr)
  
const MyComponent = (props) => ( 
  <Document>
    <Page size="A4" style={styles.page}> 
    <View style={styles.section}>
        <Text style={styles.header}>Список продуктов</Text>
        <Text style={styles.subtitle}>Продукты указаны на {servings} человека, {startDay === finishDay ? `на 1 день: ${startDay}` : `c ${startDay} по ${finishDay}`}</Text>
      
        {sort.map((item) => (
            <Text key={item.id} style={styles.table}>  
              <Text style={styles.text && item.choice && styles.choice }>{item.name} </Text>
              <Text style={styles.text}> - {Math.ceil(item.weight)} </Text>
              <Text style={styles.text}> {item.choisMeasure}</Text>
          </Text>
        ))}
       
      </View>  
    </Page>
  </Document>
); 
  return (
    
  <PDFDownloadLink style={styles.buttons}
    document={
     <MyComponent />} fileName="product.pdf">
    {({ blob, url, loading, error }) => (loading ? 'Loading document...' : 'Сохранить список')}
  </PDFDownloadLink>
)
  }

/*const PdfDownload = (props) => ( 
  <PDFViewer>
    <MyComponent />
  </PDFViewer> 
  );*/

export default PdfDownload;