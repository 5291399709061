/* Вывод списка продуктов из созданных менюшек */

import React from 'react';
import {useSelector, useDispatch} from 'react-redux'
import {DishChecking} from '../MenuCreate/Checking'
import { resulted } from '../../../utils/utils.js'
import { getFoodList, updateFoodList, postFoodList, deleteFoodList } from '../../../store/api'
import { ProductCat, PrintThisComponent} from '../../atoms' 
import { SceletonProduct, PriceCounter } from "../";
import './ProductList.css'; 
import PdfDownload  from './MyComponent';
 
export function ProductList (props) {
  const {date, menuPlane, changeMenuDay } = props 
  const { currentUser } = useSelector(state => state.currentUser)
  const {recipeDataMenu, statusMenu} = useSelector(state => state.recipes) 
  const { productList, statusProductList } = useSelector(state => state.productList) // База сохраненных продуктовых списков
  const dispatch = useDispatch();
  const { ingredients, status } = useSelector(state => state.ingredients)
  let startDay = date.length>0 ? new Date(date[0].date).toLocaleDateString() : ''
  let finishDay = date.length>0 ?  new Date(date[date.length-1].date).toLocaleDateString() : ''; 
  const [subDish, setSubDish] = React.useState([]) // Список блюд где исп-ся продукты
  const [print, setPrint] = React.useState(false) 
  const { lib } = useSelector(state => state.lib)
 //const check = Object.entries(baseProducts).length !== 0 && baseProducts.productList.some(f=> f.id === choiceIngredient._id);
  const [productFood, setProductFood] = React.useState([])
  const  servings =  currentUser.settings.sumFamily

  // Запрос списка продуктов при изменении даты
   React.useEffect(() => {
    if(Object.keys(currentUser).length !== 0) {
      dispatch(getFoodList({date: date, id: currentUser._id}))
    }
  },[currentUser, date])  
 
   // Запрос списка продуктов при изменении даты
   React.useEffect(() => { 
    if(Object.keys(productList).length > 0 && changeMenuDay) { 
      dispatch(deleteFoodList(productList._id))
    } else if(Object.keys(productList).length > 0 && !changeMenuDay) {
      setProductFood(productList.productList)
    } else {
       setProductFood(resulted(menuPlane, servings))
    }
  },[productList, changeMenuDay, menuPlane, servings])  

  //Редактирование ингредиентов из общего списка продуктов
  function handleSaveEditProducts(item) {
    props.handleChangeMenu()
    const editFoodlist = productFood.map(k=> k.id === item.id ? item : k)
    if (Object.keys(productList).length === 0) {
      dispatch(postFoodList({editFoodlist, date}))
    } else { 
      dispatch(updateFoodList({cardId: productList._id, editFoodlist}))
    } 
  }
  
  React.useEffect(() => { 
    if(Object.keys(productList).length > 0) {  
      const editFoodlist = resulted(menuPlane, currentUser.settings.sumFamily)
      editFoodlist.map(i=> {
        let arr = productList.productList.find(k => k.id === i.id); 
          if (arr) {
            let updatedObj = {
              id: i.id,
              choice: arr.choice ,
              choisMeasure: i.choisMeasure,
              excess: arr.excess, 
              weight: i.weight,
            }; 
            editFoodlist[editFoodlist.indexOf(i)] = updatedObj
          }
        })  
        dispatch(updateFoodList({cardId: productList._id, editFoodlist}))
    }
  },[servings])

  //При изменении меню и пользователя пересчитывает в каких рецептах используются продукты
  React.useEffect(() => { 
    if(status === 'resolved') { 
      setSubDish(DishChecking(menuPlane, recipeDataMenu, ingredients))
    }
  },[menuPlane]) 
  
  function handlePrint() {
    setPrint(!print)
  } 
  
                     /* Расчет стоимости продуктов*/
 
  const newarr = productFood.map((item)=> {
    const product =  ingredients.find(i => i._id === item.id)
      return {...product.price, ...item, mainMeasure: product.mainMeasure, measure: product.measure}
  })
 console.log(productFood, newarr)
  let result = newarr.reduce(function(acc,v, index){
    console.log(v)
    if(!v.choice) {
      if(v.choisMeasure === v.measureprice) {
        acc.price += (v.rate/v.weightprice)*v.weight
      } else if(v.choisMeasure !== v.measureprice) {
        const totalWeights = v.measure.find(i=> i.nameRu === v.choisMeasure)
        console.log(v.choisMeasure, v.measureprice)
          if(totalWeights !== undefined && v.measureprice !== undefined) { 
            const totalWeight = totalWeights.ratio*v.weight;
            acc.price += (v.rate/v.weightprice)*totalWeight
          }
        }
    }
    return acc
  }, {price : 0});

  return (
    <section className='foodList'>
      <h3 className='foodlist__header font35Bold' >Список продуктов </h3>
      <PrintThisComponent onClick={ props.handlePrint}/>
      <p className='foodlist__subtitle font24Bold'>Список составлен автоматически, его можно редактировать</p>
      <p className='foodlist__paragraph font24Norm'>Отметьте те продукты, которые у вас уже есть дома. Они не будут добавлены в список покупок.</p>
      <p className='foodlist__text font16Norm'>Продукты указаны на {servings} человека, {startDay === finishDay ? `на 1 день: ${startDay}` : `c ${startDay} по ${finishDay}`}</p>
      { productFood.length > 0 && statusMenu === 'resolved' ?
        <div className={`product-block ${statusProductList === 'loading' ? 'col__search' : ''}`}>
          <div className='product__buy'>
            {lib.catIn && 
            lib.catIn.map(i=> (
            <ProductCat 
              item={i} 
              key={i._id}
              checkFoodList={productFood}
              handleChoice={handleSaveEditProducts}
              subDish={subDish}
              servings={servings}
            />
            ))
            }
          </div>
          { productFood.length > 0 && statusMenu === 'resolved' &&
          <>
          <p className='product__price'>Продукты на {servings} человека 	&#8776; {Math.ceil(result.price)} руб.</p>
  
     <button className='button__pdf'> <PdfDownload  
        catIn={lib.catIn}  
        checkFoodList={productFood}
        finishDay={finishDay}
        startDay={startDay}
        servings={servings}
        />
      </button>
      </>
      }
      </div>
      :  <SceletonProduct />

      
    }
   
    
    </section>
  )
}