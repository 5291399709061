// разметка продукта в списке покупок

import React from 'react';
import './ProductItem.css'; 
import {SubList} from '../index'
import { useSelector } from 'react-redux'

export function ProductItem(props) {
  const item = props.item
  const { ingredients } = useSelector(state => state.ingredients)
  let product = ingredients.find(i => i._id === item.id) 
  const [click, setClick] = React.useState(false);
  const [weight, setWeight] = React.useState(Math.ceil(item.weight)); 
  const [calcMeasure, setCalcMeasure] = React.useState(item.choisMeasure); 
  const [choice, setChoice] = React.useState(false);
  let point = {...item}
  const wrap = product.name.split('').length > 24
  const subDish = props.subDish.filter(i=> i.id === item.id && i.choisMeasure === item.choisMeasure)
 // console.log(item, subDish, props.subDish)
  React.useEffect(()=> {
    if(item.weight > 1000 || item.weight === 1000) {
      setWeight( item.weight % 1000 === 0 ? item.weight/1000 : (Math.ceil(item.weight/100))/10)
      //setWeight(Math.ceil(item.weight/1000))
      setCalcMeasure(item.choisMeasure === 'г' ? 'кг' : item.choisMeasure === 'мл' ? 'л' : item.choisMeasure)
    } else {
      setWeight((Math.ceil(item.weight)))
      setCalcMeasure(item.choisMeasure)
    }
  }, [item]) 
  
   function hadleClick() {
    setClick(!click)
   }
  
  //Выбор ингредиента который есть в наличии
  function handleChoice() {
    point.choice = !point.choice
    props.handleChoice(point)
    setChoice(!choice)
  }    
  
  return (
    <>
    <fieldset className={`product-item ${item.choice ? ('product-item__choices'): ''}`}>
    <label className='product-item__label'>
      <input 
        type="checkbox" 
        checked={item.choice}
        onChange={handleChoice}
        className='product-item__choice' 
        name="choice" 
        id="choice"
      />
      <span className="checkbox-green item__link"></span>
      </label>
      <div className='product-item__head'>
        <p 
          onClick={hadleClick} 
          className={`product-item__title font20Norm ${item.choice ? ('product-item__title_choice'): ''}`}
        >
          {product.name}
        </p>
        <span className={`product-item__title font20Norm ${item.choice ? ('product-item__title_choice'): ''}`}> {product.trait}</span>
        <span className={`recipe__border ${item.choice ? ('recipe__border__choices'): ''}`}></span>
      </div>  
      <p className='product-item__weight font20Norm'>{weight}</p>
      <p className='product-item__measure font20Norm'>{calcMeasure}</p>
    </fieldset>
   
    {click && subDish.length> 0 &&
     <ul className='sub-dish__list'>
    {subDish.map((i, index)=> (
      <SubList key={index} item={i} servings={props.servings}/>
    ))}
    </ul>
     }
    
    </>
 )  
}
