import { configureStore, getDefaultMiddleware  } from "@reduxjs/toolkit";
import { 
  reguestSlice, 
  reguestGetSlice, 
  //reguestGetMenuSlice, 
  reguestLibrarySlice, 
  reguestGetProductSlice, 
  reguestRecipeIdSlice, 
  currentUserSlice,
  reguestProductSlice,
  reguestPlanSlice,
} from './reguestSlice'
import {IngredientsSlise} from './IngredientsSlise'
import { dataSlice } from './dataSlice' 
//import { menuWeekSlice } from './menuWeekSlice'

const middleware = getDefaultMiddleware({
  immutableCheck: false,
  serializableCheck: false,
  thunk: true,
});


const store = configureStore({
  reducer: {
    ingredients: reguestSlice.reducer,
    recipes: reguestGetSlice.reducer,
    recipe: reguestRecipeIdSlice.reducer,
    ingredientsData: IngredientsSlise.reducer,
    //menuWeek: menuWeekSlice.reducer,
    //savedmenus: reguestGetMenuSlice.reducer,
    productList: reguestGetProductSlice.reducer,
    product: reguestProductSlice.reducer,
    lib: reguestLibrarySlice.reducer,
    plans: reguestPlanSlice.reducer,
    currentUser: currentUserSlice.reducer,
    data: dataSlice.reducer,
  },
  middleware,
  devTools: process.env.NODE_ENV !== 'production',
  });

export default store;
